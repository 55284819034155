import { useMemo } from "react"
import styled from "styled-components"

import { subMonths } from "date-fns"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { MoldRiskGraphCard } from "src/components/Homes/DeviceDetails/Overview/MoldRiskGraphCard"
import { useGetGraphData } from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { HardwareType, TDevice } from "src/data/devices/types/deviceTypes"
import { useFetchHomeEvents } from "src/data/events/queries/homeEventQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useFetchGuests } from "src/data/guests/queries/guestQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { LineChartCardProps } from "src/ui/Graphs/graphTypes"
import { LineChart } from "src/ui/Graphs/LineChart"
import { LoadingPointConfig } from "src/ui/Graphs/useLoadingPoint"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { formatTemperatureUnitString } from "src/utils/l10n"

import { GraphCard } from "./GraphCard"
import { MotionGraphCard } from "./MotionGraphCard"

export type IGraphDateRange = { startDate: Date; endDate: Date }

export function DeviceGraphs({
  device,
  home,
  dateRange,
  instantlyTurnOnAlarm,
  hideGraphBorder = false,
}: {
  device: TDevice
  home: THome
  dateRange: IGraphDateRange
  instantlyTurnOnAlarm: boolean | null
  hideGraphBorder?: boolean
}) {
  const { sound, motion, humidity, temperature } = useGetGraphData({
    dateRange,
    device,
    home,
  })

  return (
    <GraphsBox>
      <MotionGraphCard
        hideGraphBorder={hideGraphBorder}
        timezone={home.timezone}
        data={motion.data}
        instantlyTurnOnAlarm={instantlyTurnOnAlarm}
        hidden={motion.hidden}
        dateRange={dateRange}
      ></MotionGraphCard>

      <NoiseGraphCard
        data={sound.data}
        hideGraphBorder={hideGraphBorder}
        timezone={home.timezone}
        thresholds={sound.thresholds}
        homeId={home.home_id}
        dateRange={dateRange}
      />
      <TemperatureGraphCard
        data={temperature.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={temperature.thresholds}
        device={device}
        dateRange={dateRange}
      />

      <HumidityGraphCard
        data={humidity.data}
        timezone={home.timezone}
        hideGraphBorder={hideGraphBorder}
        yPlotLines={humidity.thresholds}
        device={device}
        dateRange={dateRange}
      />

      <MoldRiskGraphCard
        hideGraphBorder={hideGraphBorder}
        device={device}
        home={home}
        startDate={subMonths(new Date(), 3)}
        endDate={new Date()}
      />
    </GraphsBox>
  )
}

const GraphsBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

function NoiseGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  hidden,
  thresholds,
  homeId,
  dateRange,
}: LineChartCardProps & { homeId: string; dateRange: IGraphDateRange }) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
  })
  const showUpgradeButton = !noiseIncidentFeature.available

  const unitSymbol = `dB`
  const title = `${t(langKeys.sound_noise_monitoring)} (${unitSymbol})`

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      <NoiseCardInnerBox>
        {data && (
          <LineChart
            data={data}
            tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
            timezone={timezone}
            clockType={user.clock_type}
            thresholds={thresholds}
            dateRange={dateRange}
          />
        )}

        <Show if={!!data}>
          <Divider />

          <FlexBox>
            <div>
              <MText variant="subtitle" as="span">
                {t(langKeys.noise_graph_incident_report_body)}
              </MText>{" "}
              <UpgradeIconButton
                context={"incident_reports"}
                hidden={!showUpgradeButton}
              />
            </div>

            <CreateNoiseIncidentReportButton
              variant="secondary"
              size="small"
              prefillData={{ homeId, date: dateRange.startDate }}
            />
            <IncidentReportDialogContainer context="device_page" />
          </FlexBox>
        </Show>
      </NoiseCardInnerBox>
    </GraphCard>
  )
}

const NoiseCardInnerBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  justify-content: space-between;
`

function TemperatureGraphCard({
  data,
  hidden,
  loading,
  hideGraphBorder,
  yPlotLines,
  timezone,
  device,
  dateRange,
}: LineChartCardProps & { device: TDevice; dateRange: IGraphDateRange }) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const temperatureUnit = getTemperatureUnitWithFallback(user)
  const unitSymbol = formatTemperatureUnitString(temperatureUnit)
  const title = `${t(langKeys.temperature)} (${unitSymbol})`

  const loadingPointConfig: LoadingPointConfig =
    getDeviceHardwareType(device.hardware_version).type === HardwareType.A1
      ? {
          enabled: true,
          nextUpdate: new Date(device.next_heartbeat_at ?? ""),
          lastUpdate: new Date(device.last_heartbeat_at ?? ""),
          endDate: dateRange.endDate,
        }
      : { enabled: false }

  const annotatedEvents = useEventAnnotationsForICMGraphs({
    device,
    dateRange,
  })

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          annotatedEvents={annotatedEvents}
          data={data}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 1 }}
          timezone={timezone}
          clockType={user.clock_type}
          // MON-454: Add support for temperature graph thresholds
          yPlotLines={yPlotLines}
          loadingPointConfig={loadingPointConfig}
          dateRange={dateRange}
        />
      )}
    </GraphCard>
  )
}

function HumidityGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  yPlotLines,
  hidden,
  device,
  dateRange,
}: LineChartCardProps & { device: TDevice; dateRange: IGraphDateRange }) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const unitSymbol = `%`
  const title = `${t(langKeys.humidity)} (${unitSymbol})`

  const loadingPointConfig: LoadingPointConfig =
    getDeviceHardwareType(device.hardware_version).type === HardwareType.A1
      ? {
          enabled: true,
          nextUpdate: new Date(device.next_heartbeat_at ?? ""),
          lastUpdate: new Date(device.last_heartbeat_at ?? ""),
          endDate: dateRange.endDate,
        }
      : { enabled: false }

  const annotatedEvents = useEventAnnotationsForICMGraphs({
    device,
    dateRange,
  })

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <LineChart
          annotatedEvents={annotatedEvents}
          data={data}
          tooltip={{ unit: ` ${unitSymbol}`, decimals: 0 }}
          timezone={timezone}
          clockType={user.clock_type}
          yPlotLines={yPlotLines}
          loadingPointConfig={loadingPointConfig}
          dateRange={dateRange}
        />
      )}
    </GraphCard>
  )
}

export const GraphTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

/**
 * This is an experimental feature that allows us to annotate the temperature graph with events.
 * It is not yet production ready, so do not remove the experimental flag just yet :).
 */
function useEventAnnotationsForICMGraphs({
  device,
  dateRange,
}: {
  device: TDevice
  dateRange: IGraphDateRange
}) {
  const { experimentalEventAnnotations } = useFlags()
  const { tPlaceholder } = useTranslate()

  const guestData = useFetchGuests(device.home, {
    limit: 50,
    offset: 0,
    checkin_filter: {
      to: dateRange.endDate,
    },
    checkout_filter: {
      from: dateRange.startDate,
    },
    options: {
      enabled: experimentalEventAnnotations,
    },
  })

  const thermostatEvents = useFetchHomeEvents({
    homeId: device.home,
    params: {
      limit: 50,
      offset: 0,
      event_type: [
        "thermostat_adjusted_for_guest",
        "thermostat_adjusted_to_eco",
      ],
      start_at: dateRange.startDate.toISOString(),
      end_at: dateRange.endDate.toISOString(),
    },
    options: {
      enabled: experimentalEventAnnotations,
    },
  })

  const annotatedEvents = useMemo(() => {
    if (!experimentalEventAnnotations) return []
    const tempAnnotations =
      thermostatEvents.data?.home_events.map((event) => {
        return {
          value: new Date(event.event_time ?? "").getTime(),
          text:
            tPlaceholder("Thermostat mode") +
            ": " +
            (event.event_type === "thermostat_adjusted_for_guest"
              ? tPlaceholder("On")
              : tPlaceholder("Eco")),
        }
      }) ?? []

    const guestAnnotations =
      guestData.data?.guests.flatMap((guest) => [
        {
          value: new Date(guest.checkin_time ?? "").getTime(),
          text: tPlaceholder("Stay start"),
        },
        {
          value: new Date(guest.checkout_time ?? "").getTime(),
          text: tPlaceholder("Stay end"),
        },
      ]) ?? []

    return [...tempAnnotations, ...guestAnnotations]
  }, [
    experimentalEventAnnotations,
    thermostatEvents.data?.home_events,
    guestData.data?.guests,
    tPlaceholder,
  ])

  return annotatedEvents
}
