import ReactMarkdown from "react-markdown"

import {
  GraphTitle,
  IGraphDateRange,
} from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { BarChart } from "src/ui/Graphs/BarChart"
import { ChartTooltip } from "src/ui/Graphs/ChartTooltip"
import { TBarChartSingleSeriesData } from "src/ui/Graphs/graphTypes"
import { MInfo } from "src/ui/Info/MInfo"
import { formatDate } from "src/utils/l10n"

import { GraphCard, GraphCardProps } from "./GraphCard"

export type MotionGraphCardProps = {
  data: TBarChartSingleSeriesData[]
  loading?: boolean
  hideGraphBorder: GraphCardProps["hideGraphBorder"]
  timezone: THome["timezone"]
  hidden?: boolean
  instantlyTurnOnAlarm: boolean | null
  dateRange: IGraphDateRange
}

export function MotionGraphCard({
  data,
  loading,
  hideGraphBorder,
  timezone,
  hidden,
  instantlyTurnOnAlarm,
  dateRange,
}: MotionGraphCardProps) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  function MotionInfo() {
    /** If instantlyTurnOnAlarm is null, the user might not have
     permissions to fetch the profile, or it hasn't completed fetching yet.
     at which point we don't show anything */
    if (instantlyTurnOnAlarm === null) {
      return null
    }

    return (
      <MInfo
        content={
          <ReactMarkdown>
            {instantlyTurnOnAlarm
              ? t(langKeys.device_details_motion_detail)
              : t(
                  langKeys.device_details_motion_detail_with_instantly_turn_on_alarm_off
                )}
          </ReactMarkdown>
        }
      />
    )
  }

  const title = (
    <GraphTitle>
      {t(langKeys.motion)} <MotionInfo />
    </GraphTitle>
  )

  let xAxisOptions: Highcharts.XAxisOptions = {}
  let yAxisOptions: Highcharts.YAxisOptions = {}

  if (data.length < 1) {
    xAxisOptions = {
      min: dateRange.startDate.getTime(),
      max: dateRange.endDate.getTime(),
    }

    yAxisOptions = {
      min: 0,
      max: 10,
    }
  }

  return (
    <GraphCard
      title={title}
      isLoading={loading}
      hideGraphBorder={hideGraphBorder}
      hidden={hidden}
    >
      {data && (
        <BarChart
          dataSeriesType="single"
          data={data}
          timezone={timezone}
          clockType={user.clock_type}
          tooltip={{
            decimals: 0,
            formatter: ({ date, value }) => {
              return (
                <ChartTooltip
                  label={t(langKeys.value)}
                  date={formatDate({
                    date: date.toISOString(),
                    clockType: user.clock_type,
                    timezone,
                  })}
                  value={value}
                  unit={t(langKeys.motion_graph_unit, {
                    count: value,
                  })}
                />
              )
            },
            unit: "",
          }}
          xAxisOptions={xAxisOptions}
          yAxisOptions={yAxisOptions}
        />
      )}
    </GraphCard>
  )
}
